import React from "react";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedUtb, clearSelectedUtb } from "../../redux/utb";
import schoolInfoEng from "../Education/SchoolInfoEng";

const UtbListEng = () => {
  const dispatch = useDispatch();
  const selectedUtb = useSelector((state) => state.utb.selectedUtb);

  const handleObjektClick = (school) => {
    if (selectedUtb === school) {
      dispatch(clearSelectedUtb());
    } else {
      dispatch(setSelectedUtb(school));
    }
    console.log("hit");
  };

  //   const variants = {
  //     hover: {
  //       backgroundColor: ["#111218", "#18FFBD25"],
  //       transition: {
  //         duration: 0.5,
  //         yoyo: Infinity,
  //       },
  //     },
  //   };

  const isDarkMode = useSelector((state) => state.theme.isDarkMode);
  //   const bgClass = isDarkMode ? "bg-svart" : "bg-vitt";
  const borderClass = isDarkMode ? "border-vitt" : "border-svart";
  const textClass = isDarkMode ? "text-vitt" : "text-svart";
  const yearTextClass = isDarkMode ? "text-gray-800" : "text-gray-400";

  return (
    <div className="h-screen w-full flex-col flex justify-start items-start mt-6 ">
      {schoolInfoEng.map((school) => (
        <motion.div
          className={`flex flex-col md:flex-row h-1/3 md:h-1/4   w-full border-b-2 border-l-2 ${borderClass} ${textClass}  border-dotted justify-between`}
      
          key={school.id}
          onClick={() => handleObjektClick(school)}
        >
          <div className="px-3 flex flex-col my-1 ">
            <h3 className="font-myfont3 md:text-2xl px-2 pt-2">
              {school.place}
            </h3>
            <p className="font-myfont2 font-extralight text-base md:text-ms py-0 px-2">
              {school.yrke}
            </p>
          </div>
          <div className="">
            {selectedUtb === school && (
              <div className="h-fit px-4 z-10">
                {school.points.map((point) => (
                  <div className="pt-1" key={school.id}>
                    <p className="text-xs md:text-sm">{point}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className={`${yearTextClass} pr-2 md:text-4xl text-bold`}>
            {school.year}
          </div>
        </motion.div>
      ))}
    </div>
  );
};

export default UtbListEng;
