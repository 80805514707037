const WorkInfo = [
  {
    id: 1,
    place: "Provoice",
    year: "17",
    yrke: "Telemarketer	",
    besk: "I have experience working as a telemarketer and developed my skills in selling different products to individuals. By conducting training sessions with new salespeople, I have also trained others in sales and developed skills in planning and delivering effective training programs.",
    points: [
      "- Sold various products ",
      "- Conducted training sessions with new salespeoplee ",
      "- Two-time salesperson of the year.",
    ],
  },
  {
    id: 2,
    place: "Extend",
    year: "13",
    yrke: "Ad Sales",
    besk: "During my time at Extend, I have developed skills in building and maintaining customer relationships, identifying and communicating customer needs, and designing campaigns that match their goals and desires. ",
    points: [
      "-  Sold ads on Bonnier's online newspapers",
      "- Identified and communicated customer needs and designed campaign .",
    ],
  },
  {
    id: 4,
    place: "Gimp AB",
    year: "19",
    yrke: "Warehouse Worker",
    besk: "I have experience performing physical work, including lifting and moving objects. In addition, I have developed organizational skills and have experience keeping the warehouse and inventory organized.",
    points: [
      "- Physical labor ",
      "- Ability to organize and keep the warehouse and inventory organized.",
    ],
  },
  {
    id: 3,
    place: "AbbaHaircare",
	year:'13',
    yrke: "Salesperson",
    besk: " J have experience selling scissors and other hair care products to various hair salons. During my time at the company, I have developed skills in finding new customers and demonstrating different types of products to them",
    points: [
      "- Sold scissors and other hair care products to various hair salons",
      "- Found new customers and demonstrated different types of products",
    ],
  },
  {
    id: 5,
	year:'17',

    place: "ITMaskinen",
    yrke: "Internship",
    besk: "....",
    points: ["-   .", "- "],
  },
  {
    id: 6,
	year:'19',
    place: "WX3",
    yrke: "Internship",
    besk: "....",
    points: ["-   .", "- "],
  },
  {
    id: 7,
	year:'21',

    place: "VattenInfo",
    yrke: "Internship",
    besk: "....",
    points: ["-   .", "- "],
  },
];

export default WorkInfo;
