import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const Time = () => {
  const isDarkMode = useSelector((state) => state.theme.isDarkMode);
  const textClass = isDarkMode ? "text-vitt" : "text-svart";

  const [time, setTime] = useState(new Date());

  useEffect(() => {
    // Uppdatera tiden varje sekund
    const intervalId = setInterval(() => {
      setTime(new Date());
    }, 1000);

    // Rensa upp intervallet när komponenten unmounts
    return () => clearInterval(intervalId);
  }, []);

  const hours = time.getHours().toString().padStart(2, "0");
  const minutes = time.getMinutes().toString().padStart(2, "0");
  const seconds = time.getSeconds().toString().padStart(2, "0");

  return (
    <div className={`${textClass} flex flex-row`}>
      <p className="text-ljusrosa px-1">||</p>{" "}
      <p>
        {hours}:{minutes}:{seconds}
      </p>
    </div>
  );
};

export default Time;
