import React from "react";
import OvrigtList from "./OvrigtList";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";

const Ovrigt = () => {
  const isSwe = useSelector((state) => state.language.isSwe);
  const isDarkMode = useSelector((state) => state.theme.isDarkMode);
  const bgClass = isDarkMode ? "bg-svart" : "bg-vitt";
  const borderClass = isDarkMode ? "border-vitt" : "border-svart";
  const textClass = isDarkMode ? "text-vitt" : "text-svart";
  const headerVariants = {
    hidden: {
      y: -50,
    },
    visable: {
      y: 0,
      transition: {
        type: "tween",
        duration: 0.5,
      },
    },
  };
  return (
    <div
      className={`w-screen h-full md:screen ${bgClass} ${textClass} flex flex-row mt-20 md:mt-12`}
    >
      <motion.div
        className="w-1/2 flex flex-col justify-center items-center"
        variants={headerVariants}
        initial="hidden"
        animate="visable"
      >
        {isSwe ? (
          <h2 className="font-myfont4 text-rubrikMobil md:text-10xl ">
            Vad Kan Jag
          </h2>
        ) : (
          <h2 className="font-myfont4 text-rubrikMobil md:text-10xl ">
            What can I do
          </h2>
        )}
      </motion.div>
      <div className={`w-1/2  ${borderClass} border-l-2 md:border-l-8 border-dotted `}>
        <OvrigtList />
      </div>
    </div>
  );
};

export default Ovrigt;
