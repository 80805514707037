import React from "react";
import { useNavigate } from "react-router-dom";

import WorkList from "./WorkList";
import WorkListEng from "./WorkListEng";
import { motion } from "framer-motion";

import { useSelector } from "react-redux";

const Work = () => {
  const navigate = useNavigate();

  const isSwe = useSelector((state) => state.language.isSwe);
  const isDarkMode = useSelector((state) => state.theme.isDarkMode);
  const bgClass = isDarkMode ? "bg-svart" : "bg-vitt";
//   const borderClass = isDarkMode ? "border-vitt" : "border-svart";
  const textClass = isDarkMode ? "text-vitt" : "text-svart";

  const handleClickSchool = () => {
    navigate(-1);
  };

  return (
    <div className={`w-screen h-screen ${bgClass} ${textClass} flex flex-row mt-12`}>
      <div className="w-1/2 h-screen flex flex-col justify-center items-center">
        <motion.div className="font-myfont4 text-rubrikMobil md:text-10xl ">
          {isSwe ? <h2>Arbeten</h2> : <h2> Career</h2>}
        </motion.div>
        <div
          className=" mt-2 flex justify-center items-center flex-col"
          onClick={handleClickSchool}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6 md:h-10 md:w-10"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"
            />
          </svg>
        </div>
      </div>
      <div className="w-1/2 h-screen bg-svart ">
        {isSwe ? <WorkList /> : <WorkListEng />}
      </div>
    </div>
  );
};

export default Work;
