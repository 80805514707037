import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// import { motion } from "framer-motion";
import axios from "axios";
import PopupContact from "./PopupContact";
import { setIsPoped } from "../../redux/popup";

const Contactform = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [disableButton, setDisableButton] = useState(true);

  const isSwe = useSelector((state) => state.language.isSwe);
  const isPoped = useSelector((state) => state.popup.isPoped);
  const isDarkMode = useSelector((state) => state.theme.isDarkMode);

  const bgClass = isDarkMode ? "bg-vitt" : "bg-svart";
  const textClassBtn = isDarkMode ? "text-svart" : "text-vitt";
  const textClass = isDarkMode ? "text-vitt" : "text-svart";
  const textInputClass = isDarkMode ? "text-svart" : "text-vitt";
  const borderClass = isDarkMode ? "border-vitt" : "border-svart";

  const dispatch = useDispatch();

  const togglePop = () => {
    dispatch(setIsPoped(!isPoped));
  };

  useEffect(() => {
    if (name !== "" && email !== "" && subject !== "" && message !== "") {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [name, email, subject, message]);

  const sendEmail = async (e) => {
    e.preventDefault();

    const data = {
      name,
      email,
      subject,
      message,
    };

    const response = await axios.post(
      "https://sajt-server.onrender.com/api/send",
      data
    );
    // const response = await axios.post("http://localhost:1337/api/send", data);

    console.log("subject", subject);
    console.log("response:", response.data);

    setName("");
    setEmail("");
    setSubject("");
    setMessage("");
  };

  return (
    <div
      className={`w-full h-full md:mt-14 flex flex-col justify-center items-center md:border-l-8 ${borderClass} md:border-dotted `}
    >
      <form
        className={`flex flex-col w-full h-full  justify-center md:justify-center items-center ${textClass} `}
        onSubmit={sendEmail}
      >
        {isSwe ? (
          <label htmlFor="subject">Namn</label>
        ) : (
          <label htmlFor="subject">Name</label>
        )}
        <input
          className={`${bgClass} ${textInputClass} ${textInputClass} h-8 w-4/5`}
          autoComplete="off"
          value={name}
          id="name"
          type="text"
          onChange={(e) => setName(e.target.value)}
          required
        />
        {isSwe ? (
          <label htmlFor="subject">Email</label>
        ) : (
          <label htmlFor="subject">Email</label>
        )}
        <PopupContact />
        <input
          className={`${bgClass} ${textInputClass} h-8 w-4/5`}
          onChange={(e) => setEmail(e.target.value)}
          id="email"
          value={email}
          type="text"
          required
          autoComplete="off"
        />

        {isSwe ? (
          <label htmlFor="subject">Ämne</label>
        ) : (
          <label htmlFor="subject">Subject</label>
        )}
        <input
          className={`${bgClass} ${textInputClass} h-8 w-4/5`}
          autoComplete="off"
          value={subject}
          id="subject"
          type="text"
          onChange={(e) => setSubject(e.target.value)}
          required
        />
        {isSwe ? (
          <label htmlFor="subject">Meddelande</label>
        ) : (
          <label htmlFor="subject">Message</label>
        )}
        <textarea
          //   className="bg-vitt resize-none  w-4/5 h-32"
          className={`${bgClass} ${textInputClass}  h-32 w-4/5`}
          autoComplete="off"
          value={message}
          id="message"
          type="text"
          onChange={(e) => setMessage(e.target.value)}
          required
        />
        {isSwe ? (
          <button
            className={`w-40 h-10 mt-2 ${textClassBtn} ${bgClass}`}
            onClick={() => dispatch(togglePop())}
            disabled={disableButton}
            type="submit"
          >
            Skicka
          </button>
        ) : (
          <button
            className={`w-40 h-10 mt-2 ${textClassBtn} ${bgClass}`}
            onClick={() => dispatch(togglePop())}
            disabled={disableButton}
            type="submit"
          >
            Send
          </button>
        )}
      </form>
    </div>
  );
};

export default Contactform;
