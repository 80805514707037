import React from "react";

window.onload = function () {
  const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZÅÄÖ";

  let interval = null;

  document.querySelector("h1").onmouseover = (event) => {
    let iteration = 0;
	

    clearInterval(interval);

    interval = setInterval(() => {
      event.target.innerText = event.target.innerText
        .split("")
        .map((letter, index) => {
          if (index < iteration) {
            return event.target.dataset.value[index];
          }

          return letters[Math.floor(Math.random() * 29)];
        })
        .join("");

      if (iteration >= event.target.dataset.value.length) {
        clearInterval(interval);
      }

      iteration += 1 / 5;
    }, 50);
  };
};

const WelcomeText = () => {
  return (
    <h1
      className="text-rubrikMobil md:text-rubrik font-myfont4 "
       data-value="VÄLKOMMEN"
    >
      VÄLKOMMEN
    </h1>
  );
};

export default WelcomeText;
