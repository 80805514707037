import { configureStore } from '@reduxjs/toolkit'


import languageReducer from './language';
import workReducer from './work';
import menuReducer from './menu';
import utbReducer from './utb';
import themeReducer from './theme';
import popupReducer from './popup';
// import formReducer from './form';
import weatherReducer from './weather';
import counterReducer from './counter';


export const store = configureStore({
	reducer: {
		language: languageReducer,
		menu: menuReducer,
		work: workReducer,
		utb: utbReducer,
		popup: popupReducer,
		weather: weatherReducer,
		theme: themeReducer,
		counter: counterReducer,

	},
});


