import React, { useState } from "react";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";

// const variants = {
//   hover: {
//     backgroundColor: ["#111218", "#F06FA175"],
//     transition: {
//       duration: 0.5,
//       yoyo: Infinity,
//     },
//   },
// };

const CV = () => {
  const [hoveredLinkId, setHoveredLinkId] = useState(null);

  const isSwe = useSelector((state) => state.language.isSwe);
  const isDarkMode = useSelector((state) => state.theme.isDarkMode);
  const navigate = useNavigate();

  const bgClass = isDarkMode ? "bg-svart" : "bg-vitt";
  const textClass = isDarkMode ? "text-vitt" : "text-svart";
  const borderClass = isDarkMode ? "border-vitt" : "border-svart";
  const lineClass = isDarkMode ? "via-vitt" : "via-svart";

  const hej = () => {
    navigate("/cv/work");
  };

  const hej1 = () => {
    navigate("/cv/school");
  };

  const headerVariants = {
    hidden: {
      opacity: -50,
    },
    visable: {
      opacity: 1,
      transition: {
        type: "tween",
        duration: 0.5,
      },
    },
  };

  return (
    <div className={`h-screen w-screen flex flex-col ${bgClass}`}>
      <motion.div
        className={`h-1/2 w-full flex flex-col justify-center items-center  ${textClass}`}
        variants={headerVariants}
        initial="hidden"
        animate="visable"
      >
        {isSwe ? (
          <h2 className="font-myfont4 text-rubrikMobil md:text-10xl ">CV</h2>
        ) : (
          <h2 className="font-myfont4 text-rubrikMobil md:text-10xl ">CV</h2>
        )}
        <div className="mt-2">
          {isSwe ? (
            <a
              href="https://oskarljungdahl.se/Oskar_Ljungdahl_CV.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p className="font-myfont2">Ladda ner CV som pdf</p>
            </a>
          ) : (
            <a
              href="https://oskarljungdahl.se/ENG_Oskar_Ljungdahl.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p className="font-myfont2">Download cv as pdf </p>
            </a>
          )}
        </div>
      </motion.div>
      {/* <div className="items-center justify-start flex flex-col">
        <div className="h-10 w-10 bg-ljusrosa rounded-full pt-8" />
      </div> */}

      <div
        className={`h-1/2 flex flex-row font-myfont4 md:text-6xl text-subrubrikMobil ${textClass} ${bgClass} `}
      >
        <motion.div
          className={`w-1/2 flex flex-col justify-center items-center border-dotted border-t-2 md:border-t-8 ${borderClass}`}
          onMouseEnter={() => setHoveredLinkId("link1")}
          onMouseLeave={() => setHoveredLinkId(null)}
          onClick={hej}
        >
          <NavLink to="/cv/work">
            {isSwe ? (
              <motion.h2
                variants={headerVariants}
                initial="hidden"
                animate="visable"
              >
                Arbeten
              </motion.h2>
            ) : (
              <motion.h2
                variants={headerVariants}
                initial="hidden"
                animate="visable"
              >
                Career
              </motion.h2>
            )}
            <motion.div
              className={`h-px w-w bg-gradient-to-b from-transparent ${lineClass} to-transparent`}
              // className="h-px w-w bg-lightgreen "
              style={{
                width: hoveredLinkId === "link1" ? "100%" : "0%",
                height: 1,
                transition: "width 0.8s",
              }}
            />
          </NavLink>
        </motion.div>
        <motion.div
          className={`w-1/2 flex flex-col justify-center items-center border-dotted border-t-2 md:border-t-8 md:border-l-8 border-l-2 ${borderClass} `}
          onClick={hej1}
          onMouseEnter={() => setHoveredLinkId("link2")}
          onMouseLeave={() => setHoveredLinkId(null)}
        >
          <NavLink to="/cv/school">
            <div className="w-w">
              {isSwe ? (
                <motion.h2
                  variants={headerVariants}
                  initial="hidden"
                  animate="visable"
                >
                  Utbildning
                </motion.h2>
              ) : (
                <motion.h2
                  variants={headerVariants}
                  initial="hidden"
                  animate="visable"
                >
                  Education
                </motion.h2>
              )}
              <motion.div
                className={`h-px w-w bg-gradient-to-b from-transparent ${lineClass} to-transparent`}
                // className="h-px w-w bg-lightgreen "
                style={{
                  width: hoveredLinkId === "link2" ? "100%" : "0%",

                  height: 1,

                  transition: "width 0.8s",
                }}
              />
            </div>
          </NavLink>
        </motion.div>
      </div>
    </div>
  );
};

export default CV;
