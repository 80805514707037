import { createSlice } from '@reduxjs/toolkit';

const popupSlice = createSlice({
	name: 'popup',
	initialState: { isPoped: true },
	


	reducers: {
	  setIsPoped: (state, action) => {
		state.isPoped = action.payload;
	  },
	},
  });
  
  export const { setIsPoped } = popupSlice.actions;
  export default popupSlice.reducer;