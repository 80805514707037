


const Skills = [
  {
    id: 1,
    name: "HTML5",
    genre: "Web",
  },
  {
    id: 2,
    name: "CSS",
    genre: "Style",
  },
  {
    id: 3,
    name: "JavaScript",
    genre: "Web",
  },
  {
    id: 4,
    name: "SwiftIOS",
    genre: "Programing",
  },
  {
    id: 5,
    name: "VueJS",
    genre: "Web",
  },
  {
    id: 6,
    name: "React",
    genre: "Web",
  },
  {
    id: 7,
    name: "Vuetify",
    genre: "Style",
  },
  {
    id: 8,
    name: "Bootstrap",
    genre: "Style",
  },

  {
    id: 9,
    name: "C#/.Net",
    genre: "Programing",
  },
  {
    id: 10,
    name: "Adobe",
    genre: "Design",
  },
 
  {
    id: 11,
    name: "ExpressJS",
    genre: "Backend",
  },
  {
    id: 12,
    name: "NodeJS",
    genre: "Backend",
  },
  {
    id: 13,
    name: "Tailwind",
    genre: "Style",
  },
  {
    id: 14,
    name: "Figma",
    genre: "Design",
  },
  {
    id: 15,
    name: "MongoDB",
    genre: "Backend",
  },
  {
    id: 16,
    name: "ViteJS",
    genre: "Web",
  },
  
];

export default Skills;
