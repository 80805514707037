import React from "react";
import { motion } from "framer-motion";

import { useSelector, useDispatch } from "react-redux";
import { setIsPoped } from "../../redux/popup";

const PopupContact = () => {
  const isSwe = useSelector((state) => state.language.isSwe);
  const isPoped = useSelector((state) => state.popup.isPoped);

  const dispatch = useDispatch();

  const togglePop = () => {
    dispatch(setIsPoped(!isPoped));
  };
  
  const isDarkMode = useSelector((state) => state.theme.isDarkMode);
  const bgClass = isDarkMode ? "bg-svart" : "bg-vitt";
  const textClass = isDarkMode ? "text-vitt" : "text-svart";
  const btnBgClass = isDarkMode ? "text-svart bg-vitt" : "text-vitt bg-svart";

  const variants = {
    start: {
      x: "-140vh",
      transition: {
        type: "easeInOut",
        duration: 1.8,
      },
    },
    stop: { x: 0 },
  };

  return (
    <div className="flex flex-col justify-center items-center z-50 mt-2">
      {!isPoped && (
        <motion.div
          className={`h-1/5 w-2/5 ${bgClass} ${textClass} flex flex-col justify-center items-center z-10 fixed drop-shadow2xl `}
          variants={variants}
          initial="start"
          animate="stop"
        >
          {isSwe ? (
            <p className="text-md">Email skickat!</p>
          ) : (
            <p className="text-md">Email sent!</p>
          )}
          <button
            className={`px-4 py-2 w-4/6 mt-12 ${btnBgClass}`}
            onClick={() => dispatch(togglePop())}
          >
            Ok
          </button>
        </motion.div>
      )}
    </div>
  );
};

export default PopupContact;
