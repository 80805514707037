import React from "react";
import { motion } from "framer-motion";

import { useSelector } from "react-redux";
// import { setIsSwe } from "../redux/language";
const AboutText = () => {
  //   const dispatch = useDispatch();
  const isSwe = useSelector((state) => state.language.isSwe);

  const string = `Jag är en passionerad programmerare och webbutvecklare som älskar att 
  förvandla mina ideer till verklighet. Jag brinner för att lära mig nya saker 
  och förbättra mina färdigheter."

  `;
  const stringEng = `I am a passionate programmer and web developer who loves turning my
  ideas into reality. I am driven to learn new things and improve my
  skills."

  `;

  const lettersArray = string.split("");
  const lettersArrayEng = stringEng.split("");
	const isDarkMode = useSelector((state) => state.theme.isDarkMode);
	const textClass = isDarkMode ? "text-vitt" : "text-svart";

  return (
    <div className={`pt-5 mx-5 mt-4 flex flex-col justify-center ${textClass} items-center`}>
      {isSwe ? (
        <div className="md:w-3/5 w:full flex flex-col justify-center items-center text-center">
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
            {lettersArray.map((letter, index) => (
              <motion.span
                key={index}
                initial={{ opacity: 0, x: 50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: index * 0.02 }}
              >
                {letter}
              </motion.span>
            ))}
          </motion.div>
        </div>
      ) : (
        <div className="md:w-3/5 w:full flex flex-col justify-center items-center text-center">
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
            {lettersArrayEng.map((letter, index) => (
              <motion.span
                key={index}
                initial={{ opacity: 0, x: 50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: index * 0.02 }}
              >
                {letter}
              </motion.span>
            ))}
          </motion.div>
        </div>
      )}
    </div>
  );
};

export default AboutText;
