import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./App.css";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Start from "./components/Start/Start";
import About from "./components/About/About";
import CV from "./components/CV/CV";
// import Mouse from "./components/MouseTracer";
import MouseTracer from "./components/MouseTracer";

import Work from "./components/Work/Work";
import Utbildning from "./components/Education/Utbildning";
import Kontakt from "./components/Contact/Kontakt";
import Ovrigt from "./components/Ovrigt/Ovrigt";

function App() {
  return (
    <div className="App ma-0 pa-0 ">

    <BrowserRouter>
      <Header />
      <MouseTracer />
      <Routes>
        {/* <Start /> */}
        <Route index element={<Start />} />
        <Route path="about" element={<About />} />
        <Route path="contact" element={<Kontakt />} />
        <Route path="cv" element={<CV />} />
        <Route path="cv/work" element={<Work />} />
        <Route path="cv/school" element={<Utbildning />} />
        <Route path="skills" element={<Ovrigt />} />


	

        {/* <CV />  */}
        {/* <Mouse />  */}
        {/* <About /> */}
        {/* <Utbildning />  */}
        {/* <Work /> */}
        {/*
         */}
        {/* <Ovrigt /> */}
        {/* <Kontakt /> */}
      </Routes>

      <Footer />
    </BrowserRouter>

    </div>
  );
}

export default App;
