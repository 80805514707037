import React from "react";
import { useSelector } from "react-redux";

import Skills from "./Skills";

const OvrigtList = () => {
  const isDarkMode = useSelector((state) => state.theme.isDarkMode);
  const bgClass = isDarkMode ? "bg-svart" : "bg-vitt";
  const borderClass = isDarkMode ? "border-vitt" : "border-svart";
  const textClass = isDarkMode ? "text-vitt" : "text-svart";
  const textSkilllClass = isDarkMode ? "text-lightgreen" : "text-bajen";

  const genreMap = Skills.reduce((acc, skill) => {
    if (!acc[skill.genre]) {
      acc[skill.genre] = [];
    }
    acc[skill.genre].push(skill);
    return acc;
  }, {});

  return (
    <div className="h-full  md:h-screen w-full flex-col flex justify-start items-start ">
      {Object.entries(genreMap).map(([genre, skills]) => (
        <div
          className="w-full justify-end items-ens flex flex-col text-myfont3 text-sm md:text-md "
          key={genre}
        >
          <p className={`${textSkilllClass} ${bgClass} w-full`}>{genre}</p>

          {skills.map((skill) => (
            <div
              className={`flex flex-col w-full  border-b-2  my-1 ${borderClass} border-dotted`}
              key={genre}
            >
              <p className={` ${textClass} text-xs md:text-sm`}>{skill.name}</p>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default OvrigtList;
