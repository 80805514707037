import React from "react";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedUtb, clearSelectedUtb } from "../../redux/utb";
import schoolInfo from "../Education/SchoolInfo";

const UtbList = () => {
  const dispatch = useDispatch();
  const selectedUtb = useSelector((state) => state.utb.selectedUtb);

  const handleObjektClick = (utb) => {
    if (selectedUtb === utb) {
      dispatch(clearSelectedUtb());
    } else {
      dispatch(setSelectedUtb(utb));
    }
  };

  const isDarkMode = useSelector((state) => state.theme.isDarkMode);
  //   const bgClass = isDarkMode ? "bg-svart" : "bg-vitt";
  const borderClass = isDarkMode ? "border-vitt" : "border-svart";
  const textClass = isDarkMode ? "text-vitt" : "text-svart";
  const yearTextClass = isDarkMode ? "text-gray-800" : "text-gray-400";

  

  return (
    <div className="h-screen w-full flex-col flex justify-start items-start mt-6">
      {schoolInfo.map((school) => (
        <motion.div
          className={`flex flex-col md:flex-row h-1/3 md:h-1/4   w-full border-b-2 border-l-2 ${borderClass} ${textClass}  border-dotted justify-between`}
         
          key={school.id}
          onClick={() => handleObjektClick(school)}
        >
          <div className={`px-3 flex flex-col my-1 `}>
            <h3 className="font-myfont3 md:text-2xl text-sm px-2 pt-2">
              {school.place}
            </h3>
            <p className="font-myfont2 font-extralight text-base md:text-ms py-0 px-2">
              {school.yrke}
            </p>
          </div>
          <div className="">
            {selectedUtb === school && (
              <div className="h-fit px-4 z-10">
                {school.points.map((point) => (
                  <div className="pt-1" key={school.id}>
                    <p className={`text-xs md:text-sm ${textClass} `}>
                      {point}
                    </p>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className={`${yearTextClass} pr-2 md:text-4xl text-bold`}>
            {school.year}
          </div>
        </motion.div>
      ))}
    </div>
  );
};

export default UtbList;
