import { React, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchWeather } from "../../redux/weather";
import Time from "./Time";

const Weather = () => {
  const dispatch = useDispatch();

  const weather = useSelector((state) => state.weather);
  const isSwe = useSelector((state) => state.language.isSwe);
  const isDarkMode = useSelector((state) => state.theme.isDarkMode);

  const textClass = isDarkMode ? "text-white" : "text-svart";

  //   const [weather, setWeather] = useState({});
  //   const apikey = "fb781b39c802ae515631a6b623118475";
  //   const lat = 59.278296;
  //   const lon = 18.138177;

  //   const url = `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lon}&appid=${apikey}&units=metric&`;

  useEffect(() => {
    dispatch(fetchWeather());
  }, [dispatch]);

  if (!weather) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div className={`flex flex-col w-full text-xs ${textClass}`}>
        <div className="flex flex-row">
          {isSwe ? <p>Vädret </p> : <p> The weather in </p>}
          <p className="pl-1"> {weather.name}</p>
        </div>
        <div className="flex flex-row w-full">
          <p>{weather.weather ? <p>{weather.weather[0].main}</p> : null} </p>
          {weather.main ? (
            <p className="pl-1">{weather.main.temp.toFixed()}°C</p>
          ) : null}
          <Time />
        </div>
      </div>
    </div>
  );
};

export default Weather;
