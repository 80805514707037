import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setIsSwe } from "../../redux/language";

import { NavLink } from "react-router-dom";

import { motion } from "framer-motion";
import NameIcon from "./NameIcon";

const Header = () => {
  const [hoveredLinkId, setHoveredLinkId] = useState(null);

  const dispatch = useDispatch();

  const isSwe = useSelector((state) => state.language.isSwe);
  const isDarkMode = useSelector((state) => state.theme.isDarkMode);

  const bgClass = isDarkMode ? "bg-svart" : "bg-vitt";
  const textClass = isDarkMode ? "text-vitt" : "text-svart";
  const slashClass = isDarkMode ? "text-ljusrosa" : "text-ljusrosa";
  const lineClass = isDarkMode ? "via-vitt" : "via-svart";

  const toggleLanguage = () => {
    dispatch(setIsSwe(!isSwe));
  };

  return (
    <div
      className={`w-screen flex flex-row items-center justify-between h-20 md:h-12 ${bgClass} fixed left-0 top-0`}
    >
      <div className="justify-center items-center hidden sm:block">
        <NavLink to="/">
          <NameIcon />
        </NavLink>
      </div>
      <div className=" mr-32">
        <div
          className={`flex md:flex-row flex-col md:items-center md:justify-between items-start md:font-myfont4 justify-start md:text-md ${textClass} text-xs `}
        >
          <NavLink to="/about">
            <div
              className="px-1 flex flex-col"
              onMouseEnter={() => setHoveredLinkId("link1")}
              onMouseLeave={() => setHoveredLinkId(null)}
            >
              {isSwe ? (
                <p className="px-2">Vem är Jag</p>
              ) : (
                <div className="px-2">Who iam I</div>
              )}
              {/* <div className="h-px w-w bg-gradient-to-b from-ljusrosa ${lineClass} to-ljusrosa" />
               */}
              <motion.div
                className={`h-px w-w bg-gradient-to-b from-transparent ${lineClass} to-transparent`}
                // className="h-px w-w bg-lightgreen "
                style={{
                  width: hoveredLinkId === "link1" ? "100%" : "0%",

                  height: 1,

                  transition: "width 0.8s",
                }}
              />
            </div>
          </NavLink>
          <NavLink to="/cv">
            <div
              className="px-1 flex flex-col"
              onMouseEnter={() => setHoveredLinkId("link2")}
              onMouseLeave={() => setHoveredLinkId(null)}
            >
              {isSwe ? (
                <div className="px-2">Vad har jag gjort</div>
              ) : (
                <div className="px-2">What have I done</div>
              )}
            </div>
            <motion.div
              className={`h-px w-w bg-gradient-to-b from-transparent ${lineClass} to-transparent`}
              // className="h-px w-w bg-lightgreen "
              style={{
                width: hoveredLinkId === "link2" ? "100%" : "0%",
                height: 1,
                transition: "width 0.8s",
              }}
            />
          </NavLink>

          <NavLink to="/skills">
            <div
              className="px-1 flex flex-col"
              onMouseEnter={() => setHoveredLinkId("link3")}
              onMouseLeave={() => setHoveredLinkId(null)}
            >
              {isSwe ? (
                <div className="px-2">Vad kan jag</div>
              ) : (
                <div className="px-2">What can I do</div>
              )}
            </div>
            <motion.div
              className={`h-px w-w bg-gradient-to-b from-transparent ${lineClass} to-transparent`}
              // className="h-px w-w bg-lightgreen "
              style={{
                width: hoveredLinkId === "link3" ? "100%" : "0%",
                height: 1,
                transition: "width 0.8s",
              }}
            />
          </NavLink>

          <NavLink to="/contact">
            <div
              className="px-1 flex flex-col"
              onMouseEnter={() => setHoveredLinkId("link4")}
              onMouseLeave={() => setHoveredLinkId(null)}
            >
              {isSwe ? (
                <div className="px-2">Hör av dig!</div>
              ) : (
                <div className="px-2">Hit me up!</div>
              )}
            </div>
            <motion.div
              className={`h-px w-w bg-gradient-to-b from-transparent ${lineClass} to-transparent mt-0 flex flex-col items-start justify-start`}
              // className="h-px w-w bg-lightgreen "
              style={{
                width: hoveredLinkId === "link4" ? "100%" : "0%",
                height: 1,
                transition: "width 0.8s",
              }}
            />
          </NavLink>
        </div>
      </div>
      <div
        className="flex flex-row justify-center items-end sm:items-center  mr-2"
        onClick={() => dispatch(toggleLanguage())}
        // onMouseUp={toggleMenu}
      >
        {isSwe ? (
          <div className="flex flex-row">
            <p className="text-lightgreen">SV</p>
            <p className={`${slashClass} font-bold`}>/</p>
            <p className={`${textClass}`}>EN</p>
          </div>
        ) : (
          <div className="flex flex-row ">
            <p className={`${textClass}`}>SV</p>
            <p className={`${slashClass} font-bold`}>/</p>

            <p className="text-lightgreen">EN</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
