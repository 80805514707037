import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";


// import { mouse, mousePosition } from '../../redux/mouse';

// import { useSelector } from "react-redux";

const MouseTracer = () => {
  const [mousePosition, setMousePosition] = useState({
    x: 0,
    y: 0,
  });
  const [cursorVariant, setCursorVariant] = useState("default");

  useEffect(() => {
    const mouseMove = (e) => {
      setMousePosition({
        x: e.clientX,
        y: e.clientY,
        // x: e.clientX - cursor.offsetHeight / 2,
        // y: e.clientY - cursor.offsetWidth / 2
      });
    };

    window.addEventListener("mousemove", mouseMove);
    window.addEventListener("mousemove", mouseMove);
    // const textEnter = () => setCursorVariant("text");
    // const textLeave = () => setCursorVariant("default");

    return () => {
      window.removeEventListener("mousemove", mouseMove);
    };
  }, []);

  const variants = {
    default: {
      x: mousePosition.x - 16,
      y: mousePosition.y - 16,
    },
    // text: {
    //   height: 150,
    //   width: 150,
    //   x: mousePosition.x - 75,
    //   y: mousePosition.y - 75,
    //   backgroundColor: "yellow",
    //   mixBlendMode: "difference",
    // },
  };

  return (
    <div className="flex flex-col">
      {/* <motion.div className="text-bajen" variants={variants}>
        hej
      </motion.div> */}
      <motion.div
        className="h-10 w-10  flex flex-col justify-center items-center rounded-full bg-lightgreen md:opacity-75 opacity-0 fixed left-0 top-0 z-50 pointer-events-none"
        variants={variants}
        animate={cursorVariant}
      >
        <div className="">

        </div>
      </motion.div>
    </div>

    // <div
    //   className="h-4.25 w-4.25 rounded-full bg-red-bajen fixed left-0 top-0 z-50 pointer-events-none opacity-100 transition-opacity transition-duration: 150ms"
    //   id="trailer"
    // />
  );
};

export default MouseTracer;
