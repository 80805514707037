import { React } from "react";
// import { motion } from "framer-motion";
// import { useSelector } from "react-redux";
// import MouseTracer from "../MouseTracer";
import Weather from "../Weather/Weather";
import WelcomeText from "../Welcome/WelcomeText";
	
import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";

// import { toggleTheme } from "../../redux/theme";

const Start = () => {
  const navigate = useNavigate();

  //   const isSwe = useSelector((state) => state.language.isSwe);

  const isDarkMode = useSelector((state) => state.theme.isDarkMode);
  const bgClass = isDarkMode ? "bg-svart" : "bg-vitt";
  const borderClass = isDarkMode ? "border-vitt" : "border-svart";
  const textClass = isDarkMode ? "text-vitt" : "text-svart";

  function handleClick() {
    navigate("/about");
  }

  return (
    <div className={`h-screen w-screen ${bgClass} ${textClass}flex flex-col`}>
      <div
        className={`h-3/4 w-full flex-col flex border-b-2 md:border-b-8  ${borderClass} border-dotted`}
      >
        <div className="ml-2 mt-20 md:mt-12 flex-col  flex justify-start items-start">
          <Weather />
        </div>
        <div className="flex-col  flex justify-center items-center h-full">
          {/* <div className="h-14 w-14 bg-ljusrosa rounded-full" /> */}

        </div>
        <div className={`${textClass}`} onClick={handleClick}>
          <WelcomeText />
        </div>
      </div>

      <div
        className={`h-1/4 w-screen flex flex-row justify-center items-center  ${textClass}`}
        // onClick={handleClick}
      >

      </div>
    </div>
  );
};

export default Start;
