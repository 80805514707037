const schoolInfo = [
  {
    id: 1,
    year: "21",
    place: "Jensen",
    yrke: "Frontend & websäkerhet",
    points: [
      "- JavaScript-programmering ",
      "- Frontendutveckling",
      "- Avancerad webbutveckling med JavaScript Paketering.",
      "- Vue,Node,HTML, CSS.",
    ],
  },
  {
    id: 2,
    year: "19",
    place: "Lexicon",
    yrke: ".Netutveckling",
    // desc: "hata ajk",
    points: [
      "- Applikationsutveckling",
      "- Git versionshantering",
      "- C#/.NET .",
      "- Introduktion till ASP.NET MVC",
      "- Objektorienterad C#",
    ],
  },
  {
    id: 3,
    year: "17",
    // place: "Nackademin Yrekeshögskola",
    place: "Nackademin",
    yrke: "Appliktionsutveckling",
    points: [
      "- Appliktionsutveckling med Swift.",
      "- Scrum.",
      "- HTML, JS, CSS.",
    ],
  },
  {
    id: 4,
	year:'08',
    place: "Södertörns Högskola",
    // place: "Södertörns Högskola",
    yrke: "Medieteknik A & B",
    points: [
      "- Foto och fotoredigering med adobe photoshop.",
      "- Layout med adobe illustrator ",
      "- Videoredigering med adobe premiere och aftereffect ",
    ],
  },
  {
    id: 5,
	year:'07',
    place: "Örebro Universitet",
    yrke: "Media Design",
    points: ["- Filmskapande  .", "- Kortfilm,Musikvideor ", "- Ljudläggning "],
  },
  {
    id: 6,
	year:'05',
    place: "Infokomp",
    // yrke: "It, Kommunikation och Ledarskap(Gymnasiet)",
    yrke: "IT &  Kommunikation",
    points: ["- Samhällsprogram med IT inriktning ."],
  },
];

export default schoolInfo;
