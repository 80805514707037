import React from "react";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";

const ContactText = () => {
  const isSwe = useSelector((state) => state.language.isSwe);

  const string = `Om du vill diskutera eventuella jobbmöjligheter eller har frågor om mina GitHub-projekt, vänligen skicka mig ett meddelande genom formuläret nedan. Jag ser fram emot att höra från dig och kommer att svara så snart som möjligt
  
	`;

  const stringEng = `If you'd like to discuss any job opportunities or have any questions about my GitHub projects, please feel free to send me a message using the form below. I look forward to hearing from you and will respond asap.
  
	`;
  const stringArray = string.split("");
  const stringArrayEng = stringEng.split("");
  const isDarkMode = useSelector((state) => state.theme.isDarkMode);
  const textClass = isDarkMode ? "text-vitt" : "text-svart";
  return (
    <div
      className={`pt-5 mx-5 md:mt-4 flex flex-col justify-center ${textClass} items-center`}
    >
      {isSwe ? (
        <div className="md:w-3/5 w:full flex flex-col justify-center items-center text-center md:text-sm text-xs">
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
            {stringArray.map((letter, index) => (
              <motion.span
                key={index}
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.02 }}
              >
                {letter}
              </motion.span>
            ))}
          </motion.div>
        </div>
      ) : (
        <div className="md:w-3/5 w:full flex flex-col justify-center items-center text-center md:text-sm text-xs">
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
            {stringArrayEng.map((letter, index) => (
              <motion.span
                key={index}
                initial={{ opacity: 0, x: 50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: index * 0.02 }}
              >
                {letter}
              </motion.span>
            ))}
          </motion.div>
        </div>
      )}
    </div>
  );
};

export default ContactText;
