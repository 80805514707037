import React from "react";
import { motion } from "framer-motion";

import AboutText from "../About/AboutText";
import { useSelector } from "react-redux";

const About = () => {
  const isSwe = useSelector((state) => state.language.isSwe);
  const isDarkMode = useSelector((state) => state.theme.isDarkMode);
  const count = useSelector((state) => state.counter.value);

  const bgClass = isDarkMode ? "bg-svart" : "bg-vitt";
  const borderClass = isDarkMode ? "border-vitt" : "border-svart";
  const textClass = isDarkMode ? "text-vitt" : "text-svart";
  const bgProfil = isDarkMode ? "bg-profil" : "bg-profilColor";

  const headerVariants = {
    hidden: {
      y: -50,
    },
    visable: {
      y: 0,
      transition: {
        type: "tween",
        duration: 0.5,
      },
    },
  };
  return (
    <div
      className={`h-screen w-screen flex flex-col md:flex-row ${bgClass}  ${textClass} `}
    >
      <div
        className="h-1/2 w-full md:h-screen md:w-1/2  flex flex-col justify-center items-center  "
        //   onMouseEnter={textEnter}
        //    onMouseLeave={textLeave}
      >
        {isSwe ? (
          <motion.h2
            className="font-myfont4 text-rubrikMobil md:text-10xl  md:ml-10"
            variants={headerVariants}
            initial="hidden"
            animate="visable"
          >
            Vem är Jag

          </motion.h2>
        ) : (
          <motion.h2
            className="font-myfont4 text-rubrikMobil md:text-10xl ml-10"
            variants={headerVariants}
            initial="hidden"
            animate="visable"
          >
            Who am I
          </motion.h2>
        )}
        <AboutText />
      </div>

      <div
        className={`h-1/2 w-full md:h-screen md:w-1/2 flex flex-row md:flex-col justify-center items-center border-dotted border-t-2 md:border-t-0  border-l-0 md:border-l-8 ${borderClass}`}
      >
        <div
          className={`${bgProfil} bg-cover bg-center bg-no-repeat mt-2 mx-2 w-full h-full md:h-2/5 md:w-2/5 md:rounded-full `}
          datatype="bild"
        />
      </div>
    </div>
  );
};

export default About;
