import { createSlice } from "@reduxjs/toolkit";

const apikey = "fb781b39c802ae515631a6b623118475";
const lat = 59.278296;
const lon = 18.138177;
const url = `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lon}&appid=${apikey}&units=metric&`;

const weatherSlice = createSlice({
  name: "weather",
  initialState: null,
  reducers: {
    setWeather: (state, action) => {
      return action.payload;
    },
    clearWeather: (state, action) => {
      return null;
    },
  },
});

export const { setWeather, clearWeather } = weatherSlice.actions;

export const fetchWeather = () => (dispatch) => {
  fetch(url)
    .then((response) => response.json())
    .then((weather) => dispatch(setWeather(weather)))
    .catch((error) => console.error(error));
};

// export const fetchWeatherBalls = (dispatch) => {
//   fetch(url)
//     .then((response) => response.json())
//     .then((weather) => {
//       dispatch(setWeather(weather));
//       if (weather.main.temp <= 0) {
//         alert("It's freezing outside!");
//       }
//     })
//     .catch((error) => console.error(error));
// };

export default weatherSlice.reducer;
