import React, { useEffect } from "react";
import {  useSelector } from "react-redux";	
// import { fetchWeather } from "../../redux/weather";


const NameIcon = () => {
//   const dispatch = useDispatch();

  const isDarkMode = useSelector((state) => state.theme.isDarkMode);
//   const weather = useSelector((state) => state.weather);

  const textClass = isDarkMode ? "text-vitt" : "text-svart";

//   useEffect(() => {
//     dispatch(fetchWeather());
//   }, [dispatch]);

//   if (!weather) {
//     return <div>Loading...</div>;
//   }

  return (
    <div className="flex flex-row ">
      <p className={`font-myfont4 text-sm md:text-md ${textClass} ml-2`}>
        Oskar
          <span className="bg-gradient-to-l from-svart via-lightgreen to-svart bg-clip-text text-transparent whitespace-nowrap bg-200  animate-bgPan">
            Ljungdahl
          </span>
      </p>
    </div>
  );
};

export default NameIcon;
