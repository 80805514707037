import React from "react";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import ContactForm from "./ContactForm";
import ContactText from "./ContactText";

const Kontakt = () => {
  const isSwe = useSelector((state) => state.language.isSwe);
  const isDarkMode = useSelector((state) => state.theme.isDarkMode);

  const bgClass = isDarkMode ? "bg-svart" : "bg-vitt";
  const textClass = isDarkMode ? "text-vitt" : "text-svart";
  const headerVariants = {
    hidden: {
      opacity: 0,
      x: -50,
    },
    visable: {
      x: 0,
      opacity: 1,

      transition: {
        type: "tween",
        duration: 0.5,
      },
    },
  };
  return (
    <div className={`h-screen w-screen ${bgClass} flex flex-col  md:flex-row`}>
      <div className="md:h-screen md:w-1/2  h-2/6 w-full flex flex-col  justify-center items-center">
        <motion.div
          className={`font-myfont4 text-rubrikMobil md:text-10xl ${textClass} mt-20	 md:mt-12`}
          variants={headerVariants}
          initial="hidden"
          animate="visable"
        >
          {isSwe ? <h2>Kontakt</h2> : <h2>Contact</h2>}
        </motion.div>
        <ContactText />
      </div>
      <div className="h-4/6 md:h-full md:w-1/2 flex flex-col justify-center items-center ">
        <ContactForm />
      </div>
    </div>
  );
};

export default Kontakt;
