import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedWork, clearSelectedWork } from "../../redux/work";
import { motion, } from "framer-motion";

import workInfoEng from "./WorkInfoEng";



const WorkListEng = () => {
  const selectedWork = useSelector((state) => state.work.selectedWork);
  const dispatch = useDispatch();

  const handleObjektClick = (work) => {
    if (selectedWork === work) {
      dispatch(clearSelectedWork());
    } else {
      dispatch(setSelectedWork(work));
    }
  };


  const isDarkMode = useSelector((state) => state.theme.isDarkMode);
  const bgClass = isDarkMode ? "bg-svart" : "bg-vitt";
  const borderClass = isDarkMode ? "border-vitt" : "border-svart";
  const textClass = isDarkMode ? "text-vitt" : "text-svart";
  const yearTextClass = isDarkMode ? "text-gray-800" : "text-gray-400";



//   const variants = {
// 	hover: {
// 	  backgroundColor: ["#111218", "#18FFBD25"],
// 	  transition: {
// 		duration: 0.5,
// 		yoyo: Infinity,
// 	  },
// 	},
//   };

  return (
    // <div className="h-screen w-full flex-col flex justify-start items-start mt-4  ">
    <div className={`h-screen w-full flex-col flex justify-start items-start ${bgClass} ${textClass}`}>
      {workInfoEng.map((work) => (
        <motion.div
        //   className="flex flex-col md:flex-row h-1/3 md:h-1/4   w-full border-b-2 border-l-2 border-vitt border-dotted justify-between"
		className={`flex flex-col md:flex-row md:h-1/4 md:w-full h-1/3 w-full border-b-2 border-l-4 ${borderClass} border-dotted justify-between`}
        //   variants={variants}
        //   whileHover="hover"
          key={work.id}
          onClick={() => handleObjektClick(work)} >
          <div className="px-3 flex flex-col my-1 ">
            <h3 className="font-myfont3 md:text-2xl px-2 pt-2">{work.place}</h3>
            <p className="font-myfont2 font-extralight text-base md:text-ms py-0 px-2">

              {work.yrke}
            </p>
          </div>
          <div className="">
            {selectedWork === work && (
              <div className="h-fit px-4 z-10">
                {work.points.map((point) => (
                  <div className="pt-1" key={work.id}>
                    <p className="text-xs md:text-sm">{point}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className={` ${yearTextClass} pr-2 md:text-4xl text-bold`}>
            {work.year}
          </div>
        </motion.div>
      ))}
    </div>
  );
};

export default WorkListEng;
